import React from "react";
import { FlexDiv } from "../../assets/styles/style";
import { ReadingAnswerDiv, ReadingAnswerHeader, ScriptText } from "./Style";

const ShowScriptBox = ({ answerText="", explanation=false }) => {
  const formatText = (text) => {
    return text.replace(/\\n/g, " ").replace(/%\{option_name\}/g, " [ - - - ] ");
  };

  return (
    <ReadingAnswerDiv>
      <FlexDiv
        style={{
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          borderTop: "1px solid var(--White-Theme-Gray---2, #E2E2EA)",
          borderBottom: "1px solid var(--White-Theme-Gray---2, #E2E2EA)",
          padding: "20px 0px",
        }}
      >
        <ReadingAnswerHeader> {explanation ? "Explanation:" : "Script:"} </ReadingAnswerHeader>
        <ScriptText> {formatText(answerText)}</ScriptText>
      </FlexDiv>
    </ReadingAnswerDiv>
  );
};

export default ShowScriptBox;
