export const GRAMMAR_WORDS_TO_FILTER = {
    "USA": true,
    "DNA": true,
    "VR": true,
    "AR": true,
    "SAD": true,
    "CBT": true,
    "UK": true,
    "US": true,
    "BCS": true,
    "UV": true,
    "NASA": true,
    "CDC": true,
    "TV": true,
    "MFA": true,
    "UAE": true,
    "IT": true,
    "ISS": true,
    "GIS": true,
    "GPS": true,
    "PTSD": true,
    "STEM": true,
    "FMRI": true,
    "MRI": true,
    "UNESCO": true,
    "Dr": true,
    "sci-fi": true,
    "EPA": true,
    "DHA": true,
    "ANU": true,
    "HEV": true,
    "LEDs": true,
    "IQ": true,
    "EQ": true,
    "AQ": true,
    "BHF": true,
    "NEV": true,
    "CO2": true,
    "NMR": true,
    "ModCell": true,
    "REM": true,
    "PV": true,
    "PC": true,
    "McDonald's": true,
    "BMWs": true,
    "GE": true,
    "IBM": true,
    "GDP": true,
    "IKEA": true,
    "SRO": true,
    "AD": true,
    "UCLA": true,
    "BP": true,
    "EC3": true,
    "BASIC": true,
    "SLPs": true,
    "PHD": true,
    "GPT": true
};