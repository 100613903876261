import React, {useState, useEffect} from "react";
import {findGramMistakes} from '../Writing/AiSummaryScorePopup';

const TestsNames = {
  'Speaking':[
    'Read Aloud', 'Repeat Sentence', 'Describe Image', "Re-tell Lecture", 'Answer Short Question'
  ],
  'Writing': [
    'Summarize Written Text', 'Write Essay', 'Reading & Writing: Fill in the Blanks', 'Summarize Spoken Text', 'Fill in the Blanks', 'Write from Dictation'
  ],
  'Reading': [
    'Read Aloud', 'Summarize Written Text', 'Reading & Writing: Fill in the Blanks', 'Multiple Choice, Multiple Answers', 'Re-order Paragraphs', 'Reading: Fill in the Blanks', 'Multiple Choice, Single Answer', 'Highlight Correct Summary', 'Highlight Incorrect Words'
  ],
  'Listening': [
    'Repeat Sentence', "Re-Tell Lecture", 'Answer Short Question', 'Summarize Spoken Text', 'Multiple Choice, Multiple Answers', 'Fill in the Blanks', 'Highlight Correct Summary', 'Highlight Incorrect Words', 'Multiple Choice, Single Answer', 'Select Missing Word', 'Write from Dictation'  
  ]
}

const CalculateMockTestsCategoryWiseScoresChild = ({counts = null, speakingTotScore, writingTotScore, readingTotScore, listeningTotScore}) => {

  const [scores, setScores] = useState({
    raTotal: 0, 
    raObtain: 0,
    rsTotal: 0,
    rsObtain: 0,
    diTotal: 0,
    diObtain: 0,
    rlTotal: 0,
    rlObtain: 0,
    asqTotal: 0,
    asqObtain: 0,
    swtTotal: 0,
    swtObtain: 0,
    weTotal: 0,
    weObtain: 0,
    rwfibTotal: 0,
    rwfibObtain: 0,    
    rfibTotal: 0,
    rfibObtain: 0,
    mcmaTotal: 0,
    mcmaObtain: 0,
    mcsaTotal: 0,
    mcsaObtain: 0,
    ropTotal: 0,
    ropObtain: 0,
    sstTotal: 0,
    sstObtain: 0,
    lmcmaTotal: 0,
    lmcmaObtain: 0,
    lmcsaTotal: 0,
    lmcsaObtain: 0,
    hiwTotal: 0,
    hiwObtain: 0,
    fibTotal: 0,
    fibObtain: 0,
    wfdTotal: 0,
    wfdObtain: 0,
    hcsTotal: 0,
    hcsObtain: 0,
    smwTotal: 0,
    smwObtain: 0
  });

  const giveScores = () => {
    let totalMark = {
        'Read Aloud': 0,
        'Repeat Sentence': 0,
        "Describe Image": 0,
        "Re-tell Lecture": 0,
        'Answer Short Question': 0,
        'Summarize Written Text': 0,
        "Write Essay": 0,
        'Reading & Writing: Fill in the Blanks': 0,
        'Reading: Fill in the Blanks': 0,
        'Multiple Choice, Multiple Answers': 0,
        'Multiple Choice, Single Answer': 0,
        'Re-order Paragraphs': 0,
        'Summarize Spoken Text': 0,
        'Listening: Multiple Choice, Multiple Answers': 0,
        'Listening: Multiple Choice, Single Answer': 0,
        'Highlight Incorrect Words': 0,
        'Fill in the Blanks': 0,
        'Write from Dictation': 0,
        'Highlight Correct Summary': 0,
        'Select Missing Word': 0      
    };
    let obtainMark = {
        'Read Aloud': 0,
        'Repeat Sentence': 0,
        "Describe Image": 0,
        "Re-tell Lecture": 0,
        'Answer Short Question': 0,
        'Summarize Written Text': 0,
        "Write Essay": 0,
        'Reading & Writing: Fill in the Blanks': 0,
        'Reading: Fill in the Blanks': 0,
        'Multiple Choice, Multiple Answers': 0,
        'Multiple Choice, Single Answer': 0,
        'Re-order Paragraphs': 0,
        'Summarize Spoken Text': 0,
        'Listening: Multiple Choice, Multiple Answers': 0,
        'Listening: Multiple Choice, Single Answer': 0,
        'Highlight Incorrect Words': 0,
        'Fill in the Blanks': 0,
        'Write from Dictation': 0,
        'Highlight Correct Summary': 0,
        'Select Missing Word': 0
    };    

    counts.forEach(test => {
        test.forEach(result => {
            let userResponseRaw = result['UserResponse'];
            let userResponse = null;

            if (typeof userResponseRaw === 'string') {
                try { 
                  userResponse = JSON.parse(userResponseRaw);
                } catch (e) {
                    console.error("Error parsing UserResponse:", e);
                    return;
                }
            } else if (typeof userResponseRaw === 'object' && userResponseRaw !== null) {
                userResponse = userResponseRaw;
            }

            if (userResponse && userResponse.hasOwnProperty('IsAttempted') && userResponse['IsAttempted'] === false) {
                return;
            }

            if (!userResponse) {
                return;
            }

            switch(userResponse['SubCategory']) {
                case 'Read Aloud': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Read Aloud:", e);
                        break;
                    }
                    totalMark['Read Aloud'] += 15;
                    let pronounScore  = tmp.pronunciation_score !== undefined ? tmp.pronunciation_score : tmp.pronounciation_score || 0;
                    let obtain = (tmp.content_score || 0) + (tmp.fluency_score || 0) + pronounScore;
                    obtainMark['Read Aloud'] += obtain;
                    break;
                }
                case 'Repeat Sentence': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Repeat Sentence:", e);
                        break;
                    }
                    totalMark['Repeat Sentence'] += 13;
                    let pronounScore  = tmp.pronunciation_score !== undefined ? tmp.pronunciation_score : tmp.pronounciation_score || 0;
                    let obtain = (tmp.content_score || 0) + (tmp.fluency_score || 0) + pronounScore;
                    obtainMark['Repeat Sentence'] += obtain;
                    break;
                }
                case 'Describe Image': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Describe Image:", e);
                        break;
                    }
                    totalMark["Describe Image"] += 15;
                    let obtain = (tmp.content_score || 0) + (tmp.fluency_score || 0) + (tmp.pronounciation_score || 0);
                    obtainMark["Describe Image"] += obtain;
                    break;
                }
                case 'Re-tell Lecture': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Re-tell Lecture:", e);
                        break;
                    }
                    totalMark["Re-tell Lecture"] += 15;
                    let obtain = (tmp.content_score || 0) + (tmp.fluency_score || 0) + (tmp.pronounciation_score || 0);
                    obtainMark["Re-tell Lecture"] += obtain;
                    break;
                }
                case 'Answer Short Question': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Answer Short Question:", e);
                        break;
                    }
                    totalMark["Answer Short Question"] += 1;
                    obtainMark["Answer Short Question"] += (tmp['content_score'] || 0);
                    break;
                }
                case 'Summarize Written Text': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Summarize Written Text:", e);
                        break;
                    }
                    totalMark["Summarize Written Text"] += 8;

                    let obtTmpScore = (tmp.content_score || 0) + (tmp.vocab_range_score || 0) + (tmp.form_score || 0);
                    let tmpRes = findGramMistakes(userResponse['UserResponse'], true);          
                    let gramMistakes = 0;
                    if(tmp.content_score > 0){
                        gramMistakes = (tmp.temp_mistakes?.mistakes && Array.isArray(tmp.temp_mistakes.mistakes)) ? tmp.temp_mistakes.mistakes.length : 0;
                        gramMistakes += Object.keys(tmp['corrected words'] || {}).length;
                        gramMistakes += tmpRes;          
                        gramMistakes = 2 - (gramMistakes * 0.5);
                    }
                    obtTmpScore = gramMistakes > 0 ? obtTmpScore + gramMistakes : obtTmpScore;

                    // if content score is zero it means user should have no score obtained
                    if (tmp.content_score === 0) {
                      obtTmpScore = 0;
                    }
                    obtainMark["Summarize Written Text"] += obtTmpScore;
                    break;
                }
                case 'Write Essay': {
                    let tmp = null;
                    try {
                        tmp = JSON.parse(userResponse['AI_response']);
                    } catch (e) {
                        console.error("Error parsing AI_response for Write Essay:", e);
                        break;
                    }
                    totalMark["Write Essay"] += 15;
                    let obtTmpScore = (tmp.content_score || 0) + (tmp.vocab_range_score || 0) + (tmp.form_score || 0);
                    let tmpRes = findGramMistakes(userResponse['UserResponse'], true);          
                    let gramMistakes = 0;
                    if(tmp.content_score > 0){
                        gramMistakes = (tmp.temp_mistakes?.mistakes && Array.isArray(tmp.temp_mistakes.mistakes)) ? tmp.temp_mistakes.mistakes.length : 0;
                        gramMistakes += Object.keys(tmp['corrected words'] || {}).length;
                        gramMistakes += tmpRes;          
                        gramMistakes = 2 - (gramMistakes * 0.5);
                    }
                    obtTmpScore = gramMistakes > 0 ? obtTmpScore + gramMistakes : obtTmpScore;

                    // if content score is zero it means user should have no score obtained
                    if (tmp.content_score === 0) {
                      obtTmpScore = 0;
                    }
                    obtainMark["Write Essay"] += obtTmpScore;
                    break;
                }
                case 'Summarize Spoken Text': {
                  let tmp = null;
                  try {
                      tmp = JSON.parse(userResponse['AI_response']);
                  } catch (e) {
                      console.error("Error parsing AI_response for Summarize Spoken Text:", e);
                      break;
                  }
                  totalMark["Summarize Spoken Text"] += 10;
                  let totalSumScore = (tmp.content_score || 0) + (tmp.vocab_range_score || 0) + (tmp.form_score || 0) + (tmp.spelling_score || 0);
                  let gramMistakes = 0;
                  if(tmp.content_score > 0){
                      gramMistakes = (tmp.temp_mistakes?.mistakes && Array.isArray(tmp.temp_mistakes.mistakes)) ? tmp.temp_mistakes.mistakes.length : 0;
                      gramMistakes = 2 - (gramMistakes * 0.5);
                  }
                  totalSumScore = gramMistakes > 0 ? totalSumScore + gramMistakes : totalSumScore;

                  // if content score is zero it means user should have no score obtained
                  if (tmp.content_score === 0) {
                    totalSumScore = 0;
                  }
                  obtainMark["Summarize Spoken Text"] += totalSumScore;
                  break;
                }
                case 'Write from Dictation': {
                  // Assuming AI_response contains 'total_score' and 'writing_score'
                  if (userResponse['AI_response']) {
                      let tmp = null;
                      try {
                          tmp = JSON.parse(userResponse['AI_response']);
                      } catch (e) {
                          console.error("Error parsing AI_response for Write from Dictation:", e);
                          break;
                      }
                      totalMark['Write from Dictation'] += tmp.total_score || 0;
                      obtainMark['Write from Dictation'] += tmp.writing_score || 0;
                  }
                  break;
                }
                case 'Reading & Writing: Fill in the Blanks': {
                    // For this subcategory, 'UserResponse' is already an object with necessary fields
                    let tmp = userResponse;
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Reading & Writing: Fill in the Blanks'] += tmp.correctAnswers.length;
                    }
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        obtainMark['Reading & Writing: Fill in the Blanks'] += score;
                    }
                    break;
                }
                case 'Reading: Fill in the Blanks': {
                    let tmp = userResponse;
                    
                    // Check if correctAnswers exists and has length
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Reading: Fill in the Blanks'] += tmp.correctAnswers.length;
                    }
                
                    // Check if enableSkillsData exists and is an array with at least one item
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0; // Use fallback value of 0 if score is invalid
                        obtainMark['Reading: Fill in the Blanks'] += score;
                    }
                    break;
                }
                case 'Multiple Choice, Multiple Answers': {
                    let tmp = userResponse;
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Multiple Choice, Multiple Answers'] += tmp.correctAnswers.length;
                    }
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        obtainMark['Multiple Choice, Multiple Answers'] += score;
                    }
                    break;
                }
                case 'Multiple Choice, Single Answer': {
                    let tmp = userResponse;
                    // Check if enableSkillsData exists and is an array with at least one item
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0; // Fallback value 0
                        totalMark['Multiple Choice, Single Answer'] += 1;  // Assuming each answer gets a mark of 1
                        obtainMark['Multiple Choice, Single Answer'] += score;
                    }
                    break;
                }
                case 'Re-order Paragraphs': {
                    let tmp = userResponse['submissionResult'];
                    if (tmp && tmp.correctIndexes && Array.isArray(tmp.correctIndexes)) {
                        totalMark['Re-order Paragraphs'] += tmp.correctIndexes.length;
                    }
                    if (tmp && typeof tmp.score === 'number') {
                        obtainMark['Re-order Paragraphs'] += tmp.score;
                    }
                    break;
                }
                case 'Listening: Multiple Choice, Multiple Answers': {
                    let tmp = userResponse;
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Listening: Multiple Choice, Multiple Answers'] += tmp.correctAnswers.length;
                    }
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        obtainMark['Listening: Multiple Choice, Multiple Answers'] += score;
                    }
                    break;
                }
                case 'Listening: Multiple Choice, Single Answer': {
                    let tmp = userResponse;
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        totalMark['Listening: Multiple Choice, Single Answer'] += 1;
                        obtainMark['Listening: Multiple Choice, Single Answer'] += score;
                    }
                    break;
                }
                case 'Highlight Incorrect Words': {
                    let tmp = userResponse;
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Highlight Incorrect Words'] += tmp.correctAnswers.length;
                    }
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        obtainMark['Highlight Incorrect Words'] += score;
                    }
                    break;
                }
                case 'Fill in the Blanks': {
                    let tmp = userResponse;
                    if (tmp.correctAnswers && Array.isArray(tmp.correctAnswers)) {
                        totalMark['Fill in the Blanks'] += tmp.correctAnswers.length;
                    }
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        obtainMark['Fill in the Blanks'] += score;
                    }
                    break;
                }
                case 'Highlight Correct Summary': {
                    let tmp = userResponse;
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        totalMark['Highlight Correct Summary'] += 1;
                        obtainMark['Highlight Correct Summary'] += score;
                    }
                    break;
                }
                case 'Select Missing Word': {
                    let tmp = userResponse;
                    if (tmp.enableSkillsData && Array.isArray(tmp.enableSkillsData) && tmp.enableSkillsData.length > 0) {
                        let scoreStr = tmp.enableSkillsData[0]['score'] || '0/0';
                        let score = parseInt(scoreStr.split('/')[0]) || 0;
                        totalMark['Select Missing Word'] += 1;
                        obtainMark['Select Missing Word'] += score;
                    }
                    break;
                }
                default: {
                    console.warn(`Unhandled SubCategory: ${subCategory}`);
                    break;
                }
            }
        });
    });

    setScores({
        raTotal: totalMark['Read Aloud'],
        raObtain: obtainMark['Read Aloud'],
        rsTotal: totalMark['Repeat Sentence'],
        rsObtain: obtainMark['Repeat Sentence'],
        diTotal: totalMark["Describe Image"],
        diObtain: obtainMark["Describe Image"],
        rlTotal: totalMark["Re-tell Lecture"],
        rlObtain: obtainMark["Re-tell Lecture"],
        asqTotal: totalMark["Answer Short Question"],
        asqObtain: obtainMark["Answer Short Question"],
        swtTotal: totalMark["Summarize Written Text"],
        swtObtain: obtainMark["Summarize Written Text"],
        weTotal: totalMark["Write Essay"],
        weObtain: obtainMark["Write Essay"],            
        rwfibTotal: totalMark['Reading & Writing: Fill in the Blanks'],
        rwfibObtain: obtainMark['Reading & Writing: Fill in the Blanks'],
        mcmaTotal: totalMark['Multiple Choice, Multiple Answers'],
        mcmaObtain: obtainMark['Multiple Choice, Multiple Answers'],
        mcsaTotal: totalMark['Multiple Choice, Single Answer'], 
        mcsaObtain: obtainMark['Multiple Choice, Single Answer'],
        ropTotal: totalMark['Re-order Paragraphs'],
        ropObtain: obtainMark['Re-order Paragraphs'],
        sstTotal: totalMark['Summarize Spoken Text'],
        sstObtain: obtainMark['Summarize Spoken Text'],
        lmcmaTotal: totalMark['Listening: Multiple Choice, Multiple Answers'],
        lmcmaObtain: obtainMark['Listening: Multiple Choice, Multiple Answers'],
        lmcsaTotal: totalMark['Listening: Multiple Choice, Single Answer'],
        lmcsaObtain: obtainMark['Listening: Multiple Choice, Single Answer'],
        hiwTotal: totalMark['Highlight Incorrect Words'],
        hiwObtain: obtainMark['Highlight Incorrect Words'],
        fibTotal: totalMark['Fill in the Blanks'],
        fibObtain: obtainMark['Fill in the Blanks'],
        wfdTotal: totalMark['Write from Dictation'],
        wfdObtain: obtainMark['Write from Dictation'],
        hcsTotal: totalMark['Highlight Correct Summary'],
        hcsObtain: obtainMark['Highlight Correct Summary'],
        smwTotal: totalMark['Select Missing Word'],
        smwObtain: obtainMark['Select Missing Word'],
        rfibTotal: totalMark['Reading: Fill in the Blanks'],
        rfibObtain: obtainMark['Reading: Fill in the Blanks']
    });
  }

  const getSpeakingTotal = (keyName='Read Aloud') => {
    const keyPercentages = {
      'Read Aloud': 33,
      'Repeat Sentence': 30,
      'Describe Image': 22,
      'Re-tell Lecture': 10,
      'Answer Short Question': 5
    };
    return (keyPercentages[keyName]*0.9).toFixed(1);
  }

  const getSpeakingObtained = (keyName='Read Aloud') => {

    let ra = scores['raObtain']/scores['raTotal'] * getSpeakingTotal(keyName);
    let rs = scores['rsObtain']/scores['rsTotal'] * getSpeakingTotal(keyName);
    let di = scores['diObtain']/scores['diTotal'] * getSpeakingTotal(keyName);
    let rl = scores['rlObtain']/scores['rlTotal'] * getSpeakingTotal(keyName);
    let asq = scores['asqObtain']/scores['asqTotal'] * getSpeakingTotal(keyName);
    const key = {
      'Read Aloud': ra,
      'Repeat Sentence': rs,
      'Describe Image': di,
      'Re-tell Lecture': rl,
      'Answer Short Question': asq
    }
    let res = key[keyName];
    if (isNaN(res)){
      res = 0;
    }
    return (typeof(res) === 'number')?res.toFixed(1):res;    
  }  

  const getWritingTotal = (keyName) => { 
    const keyPercentages = {
      'Summarize Written Text': 6.0,
      'Write Essay': 17.0,
      'Reading & Writing: Fill in the Blanks': 25.0,
      'Summarize Spoken Text': 6.0,
      'Write from Dictation': 28.0,
      'Fill in the Blanks': 18.0
    }    
    return (keyPercentages[keyName]*0.9).toFixed(1);    
  }

  const getWritingObtain = (keyName) => {
    let swt = scores['swtObtain']/scores['swtTotal'] * getWritingTotal(keyName);
    let we = scores['weObtain']/scores['weTotal'] * getWritingTotal(keyName);
    let rwfib = scores['rwfibObtain']/scores['rwfibTotal'] * getWritingTotal(keyName);
    let sst = scores['sstObtain']/scores['sstTotal'] * getWritingTotal(keyName);
    let wfd = scores['wfdObtain']/scores['wfdTotal'] * getWritingTotal(keyName);
    let fib = scores['fibObtain']/scores['fibTotal'] * getWritingTotal(keyName);
    const key = {
      'Summarize Written Text': swt,
      'Write Essay': we,
      'Reading & Writing: Fill in the Blanks': rwfib,
      'Summarize Spoken Text': sst,
      'Write from Dictation': wfd,
      'Fill in the Blanks': fib
    }
    let res = key[keyName];
    if (isNaN(res)){
      res = 0;
    }    
    return (typeof(res) === 'number')?res.toFixed(1):res;    
  }  

  const getReadingTotal = (keyName) => {
    const keyPercentages = {
      'Read Aloud': 27,
      'Summarize Written Text': 4,
      'Reading & Writing: Fill in the Blanks': 29,
      'Reading: Fill in the Blanks': 20,
      'Multiple Choice, Multiple Answers': 2,
      'Multiple Choice, Single Answer': 1,
      'Re-order Paragraphs': 7,
      'Highlight Correct Summary': 1,
      'Highlight Incorrect Words': 9
    } 
    return (keyPercentages[keyName]*0.9).toFixed(1);
    
  }

  const getReadingObtain = (keyName) => {
    let total = getReadingTotal(keyName);
    let ra = scores['raObtain']/scores['raTotal'] * total;
    let swt = scores['swtObtain']/scores['swtTotal'] * total;
    let fibrw = scores['rwfibObtain']/scores['rwfibTotal'] * total;
    let rfib = scores['rfibObtain']/scores['rfibTotal'] * total;
    let mcma = scores['mcmaObtain']/scores['mcmaTotal'] * total;
    let mcsa = scores['mcsaObtain']/scores['mcsaTotal'] * total;
    let rop = scores['ropObtain']/scores['ropTotal'] * total;
    let hcs = scores['hcsObtain']/scores['hcsTotal'] * total;
    let hiw = scores['hiwObtain']/scores['hiwTotal'] * total;
    const key = {
      'Read Aloud': ra,
      'Summarize Written Text': swt,
      'Reading & Writing: Fill in the Blanks': fibrw,
      'Reading: Fill in the Blanks': rfib,
      'Multiple Choice, Multiple Answers': mcma,
      'Multiple Choice, Single Answer':mcsa,
      'Re-order Paragraphs': rop,
      'Highlight Correct Summary': hcs,
      'Highlight Incorrect Words': hiw
    }
    let res = key[keyName];
    if (isNaN(res)){
      res = 0;
    }    
    return (typeof(res) === 'number')?res.toFixed(1):res;    
  }  

  const getListenTotal = (keyName) => {
    const keyPercentages = {
      'Summarize Spoken Text': 6,
       'Multiple Choice, Multiple Answers': 2,
       'Multiple Choice, Single Answer': 1,
       'Highlight Incorrect Words': 16,
       'Fill in the Blanks': 12,
       'Write from Dictation': 25,
       'Highlight Correct Summary': 1,
       'Select Missing Word': 1,
       'Repeat Sentence': 23,
       'Re-Tell Lecture': 9,
       'Answer Short Question': 4
    }
    return (keyPercentages[keyName]*0.9).toFixed(1);
  }

  const getListenObtain = (keyName) => {
    let total = getListenTotal(keyName);
    let sst = scores['sstObtain']/scores['sstTotal'] * total;
    let mcma = scores['lmcmaObtain']/scores['lmcmaTotal'] * total;
    let mcsa = scores['lmcsaObtain']/scores['lmcsaTotal'] * total;
    let hiw  = scores['hiwObtain']/scores['hiwTotal'] * total;
    let fib = scores['fibObtain']/scores['fibTotal'] * total;
    let wfd = scores['wfdObtain']/scores['wfdTotal'] * total;
    let hcs  = scores['hcsObtain']/scores['hcsTotal'] * total;
    let smw  = scores['smwObtain']/scores['smwTotal'] * total;
    let rs  = scores['rsObtain']/scores['rsTotal'] * total;
    let rl  = scores['rlObtain']/scores['rlTotal'] * total;
    let asq  = scores['asqObtain']/scores['asqTotal'] * total;

    const key = {
      'Summarize Spoken Text': sst,
       'Multiple Choice, Multiple Answers': mcma,
       'Multiple Choice, Single Answer': mcsa,
       'Highlight Incorrect Words': hiw,
       'Fill in the Blanks': fib,
       'Write from Dictation': wfd,
       'Highlight Correct Summary': hcs,
       'Select Missing Word': smw,
       'Repeat Sentence': rs,
       'Re-Tell Lecture': rl,
       'Answer Short Question': asq
    }
    let res = key[keyName];
    if (isNaN(res)){
      res = 0;
    }    
    return (typeof(res) === 'number')?res.toFixed(1):res;    
  }

  const getTotSpeakingScore = () => {
    let tempTotal = 0;
    TestsNames['Speaking'].forEach(test => {
      let tmp = parseFloat(getSpeakingObtained(test));
      tempTotal += tmp;
    })
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal<10? 10 : tempTotal 
    speakingTotScore(tempTotal);
  }

  const getTotReadingScore = () => {
    let tempTotal = 0;
    TestsNames['Reading'].forEach(test => {
      let tmp = parseFloat(getReadingObtain(test));
      tempTotal += tmp;
    })
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal<10? 10 : tempTotal;
    readingTotScore(tempTotal);
  }

  const getTotListeningScore = () => {
    let tempTotal = 0;
    TestsNames['Listening'].forEach(test => {
      let tmp = parseFloat(getListenObtain(test));
      tempTotal += tmp;
    })
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal<10? 10 : tempTotal;
    listeningTotScore(tempTotal);
  }

  const getTotWritingScore = () => {
    let tempTotal = 0;
    TestsNames['Writing'].forEach(test => {
      let tmp = parseFloat(getWritingObtain(test));
      tempTotal += tmp;
    })
    tempTotal = Math.round(tempTotal);
    tempTotal = tempTotal<10? 10 : tempTotal; 
    writingTotScore(tempTotal);
  }

  useEffect(() => {
    giveScores();
  }, [counts]);

  useEffect(() => {
    getTotSpeakingScore();
    getTotReadingScore(); 
    getTotListeningScore();
    getTotWritingScore();
  }, [scores]); 

};

export default CalculateMockTestsCategoryWiseScoresChild;
